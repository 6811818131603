/*
 * @Author: ce
 * @Date: 2022-09-01 08:49:11
 * @LastEditors: ce
 * @LastEditTime: 2022-09-05 11:48:04
 * @FilePath: /hitarget-offical/src/main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router'
import i18n from '@/language'
import '@/assets/css/index.scss'

const app = createApp(App)
app.use(store)
  .use(router)
  .use(i18n)
  .mount('#app')

