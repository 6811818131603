/*
 * @Author: ce
 * @Date: 2022-09-01 13:41:16
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 16:53:44
 * @FilePath: /hitarget-offical/src/store/modules/main.js
 */
import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    locale: 'zh',
    productId: 1
  }),
  actions: {
    setLocale(l) {
      this.locale = l
    },
    setProductId(id) {
      this.productId = id
    }
  }
})