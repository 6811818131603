<!--
 * @Author: ce
 * @Date: 2022-09-05 16:43:43
 * @LastEditors: ce
 * @LastEditTime: 2022-09-05 17:27:34
 * @FilePath: /hitarget-offical/src/views/home/components/honorsAndQualifications.vue
-->
<template>
  <div id="honors" class="honors-and-qualifications flex content-center">
    <div class="content-inner flex items-stretch">
        <div class="section-title flex flex-vertical items-start content-center w-300">
          <img :src="require('@/assets/imgs/honor-1.png')" class="h-120 w-120" alt="">
          <span class="title mt-32">{{ $t('home.sectionTitle3') }}</span>
          <span class="tip mt-16">{{ infos[locale].tip }}</span>
        </div>
        <div class="honor-content flex items-center">
          <div class="honor-list flex flex-vertical items-start content-center ml-50">
            <div class="honor-item" v-for="(item, index) in infos[locale].honors" :key="index">
              <h2 class="data">{{ item.data }}</h2>
              <span class="label">{{ item.label }}</span>
            </div>
          </div>
          <div class="photo-board h-405 w-847 ml-104">
            <div class="photo-item" :class="{ 'w-photo': zIndex <= 2, 's-photo': zIndex > 2 }" v-for="(z, zIndex) in infos[locale].photos" :key="zIndex">
              <img :src="z" class="w-full h-full" alt="">
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)

  const infos = ref({
    zh: {
      tip: '成熟应用案例,收获众多行业奖项',
      honors: [
        {
          data: '8+',
          label: '国家级奖项'
        },
        {
          data: '80+',
          label: '软件著作权'
        },
        {
          data: '8',
          label: '专利发明'
        }
      ],
      photos: [
        require('@/assets/imgs/honor-2.png'),
        require('@/assets/imgs/honor-3.png'),
        require('@/assets/imgs/honor-4.png'),
        require('@/assets/imgs/honor-5.png'),
        require('@/assets/imgs/honor-6.png'),
        require('@/assets/imgs/honor-7.png'),
        require('@/assets/imgs/honor-8.png'),
        require('@/assets/imgs/honor-9.png')
      ]
    },
    en: {
      tip: 'Mature application cases, harvesting many industry awards',
      honors: [
        {
          data: '8+',
          label: 'National Awards'
        },
        {
          data: '80+',
          label: 'Software Copyright'
        },
        {
          data: '8',
          label: 'Patent Inventions'
        }
      ],
      photos: [
        require('@/assets/imgs/honor-2.png'),
        require('@/assets/imgs/honor-3.png'),
        require('@/assets/imgs/honor-4.png'),
        require('@/assets/imgs/honor-5.png'),
        require('@/assets/imgs/honor-6.png'),
        require('@/assets/imgs/honor-7.png'),
        require('@/assets/imgs/honor-8.png'),
        require('@/assets/imgs/honor-9.png')
      ]
    }
  })
</script>

<style lang="scss" scoped>
  .honors-and-qualifications {
    background: linear-gradient(101.53deg, #BED8FF 1.64%, #7C9AD7 101.93%);
    .content-inner {
      width: 1400px;
      .section-title {
        padding: 0 20px 0 50px;
        background: linear-gradient(168.33deg, #7AA6FF 0%, rgba(33, 76, 163, .3) 100%);
        .title {
          font-family: 'HarmonyOS Sans';
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: #FFFFFF;
        }
        .tip {
          font-family: 'HarmonyOS Sans';
          font-size: 16px;
          line-height: 19px;
          color: #D9E4FF;
        }
      }
      .honor-content {
        padding: 50px 0;
        .honor-list {
          .honor-item {
            margin-top: 60px;
            &:first-child {
              margin-top: 0;
            }
            .data {
              font-family: 'HarmonyOS Sans';
              font-style: italic;
              font-weight: 900;
              font-size: 50px;
              line-height: 61px;
              color: #FFFFFF;
            }
            .label {
              font-family: 'HarmonyOS Sans';
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #E1EBFF;
            }
          }
        }
        .photo-board {
          padding: 20px 30px;
          background: linear-gradient(123.9deg, #FFFFFF 0%, #C3CFE7 100%);
          border-radius: 16px;
          box-sizing: border-box;
          flex-shrink: 0;
          .photo-item {
            float: left;
            &.w-photo {
              width: 250px;
              height: 169px;
              margin-right: 18px;
            }
            &.s-photo {
              width: 138px;
              height: 185px;
              margin-right: 23px;
              margin-top: 11px;
            }
              &:last-child {
                margin-right: 0;
              }
              &:nth-child(3) {
                margin-right: 0;
              }
          }
        }
      }
    }
  }
</style>