<!--
 * @Author: ce
 * @Date: 2022-09-01 13:25:04
 * @LastEditors: ce
 * @LastEditTime: 2022-09-06 15:24:00
 * @FilePath: /hitarget-offical/src/App.vue
-->
<template>
  <router-view/>
</template>

<style lang="scss">
  #app {
    font-family: HarmonyOS Sans;
    overflow: hidden;
    min-width: 1440px;
  }
</style>
