<!--
 * @Author: ce
 * @Date: 2022-09-01 14:22:27
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 15:17:28
 * @FilePath: /hitarget-offical/src/components/nav/index.vue
-->
<template>
  <ul class="nav-wrap flex items-center">
    <li class="nav-item mr-30" :class="{ active: route.name === 'home' }">
      <a href="#home">{{ $t('menu.home') }}</a>
    </li>
    <li class="nav-item mr-30" :class="{ active: route.name === 'product' }">
      <a href="#product">{{ $t('menu.product') }}</a>
    </li>
    <li class="nav-item" :class="{ active: route.name === 'contact' }">
      <a href="#contact">{{ $t('menu.contact') }}</a>
    </li>
  </ul>
</template>

<script setup>
  import { useRoute } from 'vue-router'
  const route = useRoute()
</script>

<style lang="scss" scoped>
</style>