<!--
 * @Author: ce
 * @Date: 2022-09-01 15:38:28
 * @LastEditors: ce
 * @LastEditTime: 2022-09-08 16:15:16
 * @FilePath: /hitarget-offical/src/components/layout/i18n.vue
-->
<template>
  <div class="i18n">
    <span 
      :class="{ active: locale === 'zh' }"
      @click="handleClick('zh')"
      class="pr-8"
    >中</span>/ 
    <span 
      class="pl-8"
      :class="{ active: locale === 'en' }"
      @click="handleClick('en')"
    >EN</span>
  </div>
</template>

<script setup>
  import { useMainStore } from '@/store/modules/main'
  import { storeToRefs } from 'pinia'
  import { getCurrentInstance } from 'vue'
  const instance = getCurrentInstance()
  const store = useMainStore()
  const { locale } = storeToRefs(store)
  const handleClick = (l) => {
    store.setLocale(l)
    instance.ctx.$i18n.locale = l
  }
</script>