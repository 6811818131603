/*
 * @Author: ce
 * @Date: 2022-09-01 13:39:52
 * @LastEditors: ce
 * @LastEditTime: 2022-09-01 14:42:13
 * @FilePath: /hitarget-offical/src/store/index.js
 */
import { createPinia } from 'pinia'

const store = createPinia()

export default store