<!--
 * @Author: ce
 * @Date: 2022-09-01 14:17:42
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 16:46:08
 * @FilePath: /hitarget-offical/src/components/layout/index.vue
-->
<template>
  <div class="page-container" id="viewPort" ref="pageRef" :class="{ 'fixed-header': scrolled }">
    <div 
      class="back-to-top flex flex-vertical items-center content-center h-72 w-72"
      v-show="backShow"
      @click="handleBack"
    >
      <img :src="require('@/assets/imgs/back.png')" class="h-40 w-40" alt="">
      <span>回到顶部</span>
    </div>
    <SystemHeader />
    <main class="main-container">
      <router-view></router-view>
    </main>
    <SystemFooter/>
  </div>
  
</template>

<script setup>
  import SystemHeader from './header.vue'
  import SystemFooter from './footer.vue'
  import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
  const pageRef = ref(null)
  const scrolled = ref(false)
  const backShow = ref(false)
  const handleBack = () => {
    pageRef.value.scrollTo({
        left: 0,
        top: 0,
        behavior: 'instant'
    })
  } 
  onMounted(() => {
    nextTick(() => {
      pageRef.value.addEventListener('scroll', () => {
        const scrollTop = pageRef.value.scrollTop
        if(scrollTop > 0) {
          scrolled.value = true
        } else {
          scrolled.value = false
        }
        if(scrollTop  >= 500) {
          backShow.value = true
        } else {
          backShow.value = false
        }
      })
    })
  })
  onBeforeUnmount(() => {
    pageRef.value.removeEventListener('scroll')
  })
</script>

<style lang="scss">
  .page-container {
    position: relative;
    min-width: 1450px;
    height: 100vh;
    overflow: overlay;
    transition: .56s ease-in-out;
  }
  .main-container {
    z-index: 0;
  }
  .fixed-header {
    z-index: 0;
    .layout-header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .main-container {
      padding-top: 72px;
    }
  }
  .back-to-top {
    position: fixed;
    right: 120px;
    bottom: 80px;
    border: 1px solid #B2BACD;
    border-radius: 4px;
    cursor: pointer;
    span {
      font-family: 'HarmonyOS Sans SC';
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #B2BBCD;
    }
  }
</style>