<!--
 * @Author: ce
 * @Date: 2022-09-05 13:11:47
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 11:08:09
 * @FilePath: /hitarget-offical/src/views/home/components/infoBanner.vue
-->
<template>
  <div id="infoBanner" class="info-banner">
    <swiper
      :modules="[Pagination, Autoplay]"
      :slides-per-view="1"
      :space-between="10"
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 5000 }"
    >
      <swiper-slide v-for="(item, index) in infos[locale]" :key="index">
        <div class="banner-item"  :style="{ backgroundImage: `url(${item.img})` }">
          <div class="info-content">
            <div class="inner-info">
              <h2 class="title main-title">
                <span>{{ item.title }}</span>
              </h2>
              <div class="text mt-16">
                <p v-for="(z, zIndex) in item.text" :key="zIndex">{{ z }}</p>
              </div>
              <div class="operation mt-40">
                <button class="btn-default" v-if="item.contact" @click="handleContactNow">
                  <span>{{ $t('btn.contactNow') }}</span>
                </button>
                <button class="btn-default plain-btn" @click="handleIntroduction(item)" v-if="item.introduction">
                  <span>{{ $t('btn.businessIntroduction') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  // eslint-disable-next-line no-unused-vars
  import { Navigation, Pagination, Autoplay } from 'swiper';
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  // Import Swiper styles
  import 'swiper/swiper-bundle.css';
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  import { useRouter } from 'vue-router'
  const router = useRouter()
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const  infos = ref({
    en: [
    {
      id: 0,
      title: 'The global leader in space-time information solutions',
      text: [ 'Zhejiang Hi Target Space Information Technology Co. (hereinafter referred to as "the Company"), located in Zhejiang Geographic Information Industry Park, is a holding subsidiary of Guangzhou Hi Target Satellite Navigation Technology Company Limited (stock code: 300177), which has the qualification certificate of Class A in surveying and mapping, national high-tech enterprise, ISO9001 quality system certification, CMMI5 software capability maturity certification, computer information system integration qualification and other qualifications.','The company takes the integrated application of visualized spatial information as the core, and provides all-factor physical realistic 3D spatial information products and technical services. At present, the company has formed the overall solutions based on realistic 3D platform, tilt photography monolithic modeling, digital countryside, intelligent hub, intelligent campus, intelligent municipalities, historical buildings, auxiliary planning and other multi-field solutions.'],
      img: require('@/assets/imgs/banner-img-1.png'),
      contact: 1,
      introduction: 1
    },
    {
      id: 6,
      title: 'Future Community',
      text: ['Zhejiang Hi Target‘s future community digital twin platform is based on the core concept of "integration of physical space, digital space and social space", and the digital standard of "three and nine scenes" for the future community of Zhejiang Province, to create an innovative, ecological, technological, intelligent, composite and connotative future community. The future community is innovative, ecological, technological, intelligent, complex and connotative. First of all, we will build a geographic information town-wide application capability center, including IOT perception, data warehouse, business center, CIM platform and digital twin, and focus on creating indoor and outdoor integrated navigation and geographic information town AR scenes.'],
      img: require('@/assets/imgs/banner-img-2.png'),
      contact: 0,
      introduction: 1
    }
  ],
  zh: [
    {
      id: 0,
      title: '时空信息解决方案全球领先者',
      text: [ '浙江中海达空间信息技术有限公司（以下简称“公司”），坐落于浙江地理信息产业园，是广州中海达卫星导航技术股份有限公司（股票代码：300177）旗下的控股子公司，具有测绘甲级资质证书、国家高新技术企业、ISO9001质量体系认证 、CMMI5软件能力成熟度认证、计算机信息系统集成资质等资质认证。','公司以可视化空间信息的集成应用为核心，提供全要素实体化实景三维空间信息产品与技术服务。目前已形成立足实景三维平台、倾斜摄影单体化建模、数字乡村、智慧枢纽、智慧校园、智慧市政、历史建筑、辅助规划等多领域整体解决方案。'],
      img: require('@/assets/imgs/banner-img-1.png'),
      contact: 1,
      introduction: 1
    },
    {
      id: 6,
      title: '未来社区',
      text: ['浙江中海达未来社区数字孪生平台，以“物理空间、数字空间和社会空间三空间融合”为核心理念，以浙江省未来社区“三化九场景”为数字化标准，打造创新的、生态的、科技的、智能的、复合的、有内涵的未来社区。首先构建地理信息小镇全域应用能力中心，包括物联感知、数据仓、业务中台、CIM平台、数字孪生五大全域数字引擎，重点打造室内外一体化导航、地信小镇AR场景。'],
      img: require('@/assets/imgs/banner-img-2.png'),
      contact: 0,
      introduction: 1
    }
  ]
  })
  const handleContactNow = () => {
    router.push('/contact')
  }

  const handleIntroduction = ({ id }) => {
    console.log('id', id)
    if(id) {
      router.push({
        path: '/product',
        query: {
          id
        }
      })
    } else {
      router.push({
        path: '/product'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .info-banner {
    .banner-item {
      height: 640px;
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .info-content {
        width: 1400px;
        .inner-info {
          width: 784px;
        }
        .title {
          font-family: 'HarmonyOS Sans';
          font-weight: 700;
          font-size: 40px;
          // line-height: 47px;
          letter-spacing: 0.07em;
          color: #FFFFFF;
        }
        .text {
          font-family: 'HarmonyOS Sans';
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: justify;
          color: #FFFFFF;
          text-shadow: 0px 4px 8px #000000;
        }
      }
    }
    :deep(.swiper) {
      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 28px;
          height: 4px;
          border-radius: 0;
          background-color: rgba(121, 151, 196, 0.58);
          transition: .28s;
          &.swiper-pagination-bullet-active {
            background-color: #226CFF;
            width: 64px;
          }
        }
      }
    }
  }

  .operation {
    .btn-default {
      padding: 12px 24px;
      cursor: pointer;
      border: 1px solid #226CFF;
      span {
        font-family: 'HarmonyOS Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
      &:nth-child(1) {
        background: #226CFF;
        margin-right: 32px;
        span {
          color: #FFFFFF;
        }
      }
      &:nth-child(2){
        background: transparent;
        span {
          color: #4B87FF;
        }
      }
}

  }
</style>