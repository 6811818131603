/*
 * @Author: ce
 * @Date: 2022-09-01 10:34:51
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 14:15:15
 * @FilePath: /hitarget-offical/src/language/lang/en.js
 */
const en_lang = {
  menu: {
    home: 'home',
    product: 'product',
    contact: 'contact'
  },
  btn: {
    contactNow: 'Contact Now',
    businessIntroduction: 'Business Introduction'
  },
  home: {
    sectionTitle1: 'Business Introduction',
    sectionTitle2: 'Our Team',
    sectionTitle3: 'Honors and Qualifications'
  },
  product: {
    title1: 'Program Advantages',
    title2: 'Application Cases',
    title3: 'Contracted Projects',
    title4: 'Product Applications',
    title5: 'Product Showroom',
    title6: 'Mapping work',
    label1: 'Clients',
    label2: 'Achievement Time',
    label3: 'Project Location',
    label4: 'Cooperation Consulting Tel',
    label5: 'Scan & Follow',
    label6: 'The person in charge of the enterprise WeChat',
    label7: 'Underground pipeline detection',
    label8: 'Underground space mapping',
    label9: 'Before processing',
    label10: 'After treatment'
  },
  contact: {
    title1: 'Cooperation Consulting',
    label1: 'Tel',
    title2: 'Contact information'
  }

}

export default en_lang