<!--
 * @Author: ce
 * @Date: 2022-09-05 15:42:17
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 17:13:03
 * @FilePath: /hitarget-offical/src/views/home/components/researchTeam.vue
-->
<template>
  <div id="researchTeam" class="research-team flex content-center">
    <div class="research-inner flex items-stretch">
      <div class="section-title flex content-center items-start flex-vertical w-300">
        <img :src="require('@/assets/imgs/research-team-1.png')" alt="" class="icon h-120 w-120">
        <div class="title mt-32">{{ $t('home.sectionTitle2') }}</div>
        <p class="tip mt-16">{{ infos[locale].tip }}</p>
      </div>
      <div class="team-info">
        <div class="info-inner">
          <div class="title">{{ infos[locale].title1 }}</div>
          <p class="text mt-24">{{ infos[locale].text1 }}</p>
          <div class="leader mt-32">
            <span class="title-1">{{ infos[locale].title2 }}</span>
            <span class="name mt-40">{{ infos[locale].name }}</span>
            <p class="text-1 w-643 mt-8">{{ infos[locale].text2 }}</p>
            <div class="leader-photo w-237 h-270">
              <img :src="require('@/assets/imgs/leader-photo.png')" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)

  const infos = ref({
    zh: {
      tip: '校企联合,优秀的科研团队支撑',
      title1: '团队介绍',
      text1: '公司现有员工200余人，拥有专业技术人员150余人，其中具备高级职称10人，中级职称20人，博士十余人，80%以上员工为本科学历及以上。公司建立了以西南交通大学地球科学与环境工程学院和武汉大学测绘遥感信息工程国家重点实验室为依托，以长江学者朱庆教授领衔、国内外知名高校的多名博士联合的产学研用一体化的创新技术转化团队。',
      title2: '首席科学家',
      name: '朱庆',
      text2: '长江学者、教授、博士生导师、四川省“千人计划”特聘教授、自然资源部科技领军人才、新世纪百千万人才工程国家级人选、原测绘遥感信息工程国家重点实验室副主任、轨道交通工程信息化国家重点实验室学术委员、先后承担多项国家973、863和自然科学基金项目、十三五国家重点研发专项、参加制定了DEM和城市三维建模技术国家规范。'
    },
    en: {
      tip: 'University-enterprise cooperation, excellent research team support',
      title1: 'Team Introduction',
      text1: 'The company has more than 200 employees, with more than 150 professional and technical personnel, including 10 people with senior titles, 20 people with intermediate titles, more than 10 doctors, and more than 80% of the employees with bachelor‘s degree or above. The company has established an innovative technology transformation team based on the School of Earth Science and Environmental Engineering of Southwest Jiaotong University and the State Key Laboratory of Mapping and Remote Sensing Information Engineering of Wuhan University, led by Professor Zhu Qing, a Changjiang scholar, and a number of doctors from famous universities at home and abroad.',
      title2: 'Chief Scientist',
      name: 'Zhu Qing',
      text2: ' is  a professor and doctoral supervisor, a special professor of "Thousand Talents Plan" of Sichuan Province, a science and technology leader of the Ministry of Natural Resources, a national candidate of the Hundred Million Talents Project in the new century, a deputy director of the former State Key Laboratory of Mapping and Remote Sensing Information Engineering, an academic member of the State Key Laboratory of Rail Transit Engineering Informatization, and has undertaken many national 973, 863 and Natural Science Foundation projects, the 13th Five-Year National Key R&D Special Project, and participated in the formulation of national specifications for DEM and urban 3D modeling technology.'
    }
  })
</script>

<style lang="scss" scoped>
  .research-team {
    background-color: #fff;
    padding: 80px 0px 110px 0 ;
    .research-inner {
      width: 1400px;
      // margin: 0 auto;
      .section-title {
        background: linear-gradient(168.33deg, #EFF4FF 0%, rgba(207, 218, 239, 0) 100%);
        border-radius: 12px 0px 0px 12px;
        padding: 0px 38px 0 50px;
        background-image: url('~@/assets/imgs/research-team-2.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .title {
          font-family: 'HarmonyOS Sans';
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: #202632;
          text-align: left;
        }
        .tip {
          font-family: 'HarmonyOS Sans';
          font-size: 16px;
          line-height: 19px;
          color: #626773;
        }
      }
      .team-info {
        flex: 1;
        background-image: url('~@/assets/imgs/research-team-3.png');
        backdrop-filter: blur(40px);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 0px 12px 12px 0px;
        .info-inner {
          padding: 80px 85px 70px;
          background: linear-gradient(112.71deg, rgba(109, 155, 223, .8) 13.3%, #B7CEF0 79.41%);
        }
        .title {
          font-family: 'HarmonyOS Sans';
          font-weight: 500;
          font-size: 28px;
          line-height: 26px;
          color: #FFFFFF;
        }
        .text {
          font-family: 'HarmonyOS Sans';
          font-size: 18px;
          line-height: 26px;
          color: #FFFFFF;
        }
        .leader {
          position: relative;
          background: rgba(0, 60, 179, 0.2);
          border-radius: 24px;
          padding: 32px 24px;
          .title-1 {
            font-family: 'HarmonyOS Sans';
            font-weight: 500;
            font-size: 28px;
            line-height: 26px;
            color: #FFFFFF;
          }
          .name {
            font-family: 'HarmonyOS Sans';
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            color: #FFFFFF;
            display: block;
          }
          .text-1 {
            font-family: 'HarmonyOS Sans';
            font-size: 14px;
            line-height: 26px;
            color: #FFFFFF;
          }
          .leader-photo {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>