/*
 * @Author: ce
 * @Date: 2022-09-01 10:34:56
 * @LastEditors: ce
 * @LastEditTime: 2022-09-07 18:18:39
 * @FilePath: /hitarget-offical/src/language/lang/zh.js
 */
const zh_lang = {
  menu: {
    home: '公司首页',
    product: '产品展厅',
    contact: '联系我们'
  },
  btn: {
    contactNow: '立即联系',
    businessIntroduction: '业务介绍'
  },
  home: {
    sectionTitle1: '业务介绍',
    sectionTitle2: '企业团队',
    sectionTitle3: '荣誉资质'
  },
  product: {
    title1: '方案优势',
    title2: '应用案例',
    title3: '承接项目',
    title4: '产品应用',
    title5: '产品展厅',
    title6: '测绘工作',
    label1: '服务客户',
    label2: '落地时间',
    label3: '落地城市',
    label4: '合作咨询电话',
    label5: '扫码关注',
    label6: '负责人企业微信',
    label7: '地下管线探测',
    label8: '地下空间测绘',
    label9: '处理前',
    label10: '处理后'
  },
  contact: {
    title1: '合作咨询',
    label1: '咨询电话',
    title2: '联系方式'
  }
}

export default zh_lang