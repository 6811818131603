/*
 * @Author: ce
 * @Date: 2022-09-01 08:49:11
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 15:15:38
 * @FilePath: /hitarget-offical/src/router/index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home/index.vue'
import Layout from '../components/layout/index.vue'
import { nextTick } from 'vue'
const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/product',
        name: 'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/index.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.afterEach((to) => {
  const viewPort = document.getElementById('viewPort')
  const { query: { hash } } = to
  if (hash) {
    nextTick(() => {
      const target = document.getElementById(hash)
      if(target) {
        const top = target.offsetTop
        viewPort.scrollTo({
          left: 0,
          top: top - 100,
          behavior: 'smooth'
        })
      }
    })
  } else {
    viewPort.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
})
export default router
