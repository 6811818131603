/*
 * @Author: ce
 * @Date: 2022-09-01 10:34:41
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 16:56:12
 * @FilePath: /hitarget-offical/src/language/index.js
 */
import { createI18n } from "vue-i18n";
import zh from './lang/zh';
import en from './lang/en';
import { useMainStore } from "../store/modules/main.js";
import store from '@/store/index'
const mainStore = useMainStore(store)
// const languages = navigator.languages
// console.log("languages", languages);
// if(languages.some(item => !/en/.test(item))) {
//   mainStore.setLocale('zh')
// } else {
//   mainStore.setLocale('en')
// }
const i18n = createI18n({
  locale: mainStore.locale, // 初始化配置语言 
  messages: { zh, en }
});
export default i18n