<!--
 * @Author: ce
 * @Date: 2022-09-01 14:08:21
 * @LastEditors: ce
 * @LastEditTime: 2022-09-01 14:59:09
 * @FilePath: /hitarget-offical/src/components/logo/index.vue
-->
<template>
  <div class="logo flex items-center">
    <div title="浙江中海达" class="w-82 h-30 mr-22"/>
    <div class="w-127 h-33" title="智慧视图"/>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>