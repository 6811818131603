<!--
 * @Author: ce
 * @Date: 2022-09-01 16:22:35
 * @LastEditors: ce
 * @LastEditTime: 2022-10-08 14:37:28
 * @FilePath: /hitarget-offical/src/components/layout/footer.vue
-->
<template>
  <footer class="layout-footer">
    <div class="footer-inner">
      <div class="info flex items-center content-between">
        <div class="base-info flex flex-vertical">
          <div class="simple-log w-196 h-28"></div>
          <div class="contcat flex flex-vertical mt-24">
            <span class="label">{{ infos[locale].label1 }}</span>
            <span class="tel mt-8">{{ infos[locale].text1 }}</span>
            <span class="location mt-8">{{ infos[locale].location }}</span>
          </div>
          <div class="wechat mt-24">
            <div class="qr-code h-100 w-100 mb-8"></div>
            <span class="label">{{ infos[locale].qrcode }}</span>
          </div>
        </div>
        <div class="footer-nav">
          <div class="nav-container flex content-between">
            <ul
              class="nav-section flex flex-vertical mr-10 items-start"
              v-for="(item, index) in infos[locale].list"
              :key="index"
            >
              <li
                class="nav-item mt-24"
                v-for="(z, zIndex) in item"
                :key="zIndex"
                @click="handleClick(z)"
              >
                {{ z.label }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="company-right flex items-center content-between">
        <div class="flex items-center">
          <img :src="require('@/assets/imgs/police.png')" class="h-20 w-20" />
          <!-- <a href="https://beian.miit.gov.cn/" target="__blank" class="mr-32 pl-8">浙公网安备 33052102000313号</a>   -->
          <a target="__blank" href="https://beian.miit.gov.cn/"
            >工信部备案号：浙ICP备16010711号-1</a
          >
        </div>
        <div>
          Copyright © 2016-{{ year }} 浙江中海达空间信息技术有限公司 版权所有
          法律声明
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import store from "@/store/index";
import { useMainStore } from "@/store/modules/main.js";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
const router = useRouter();
const mainStore = useMainStore(store);
const { locale } = storeToRefs(mainStore);
const year = new Date().getFullYear();
const handleClick = ({ type, value, path }) => {
  if (type === "page") {
    router.push(path);
  } else if (type === "detail") {
    router.push({
      path: path,
      query: {
        id: value,
      },
    });
  } else if (type === "pageHash") {
    router.push({
      path: path,
      query: {
        hash: value,
      },
    });
  }
};
const infos = ref({
  zh: {
    label1: "联系我们",
    text1: "0572-88845214",
    location: "联系地址: 浙江省德清县科技新城科源路10号1幢10、11F",
    qrcode: "扫码关注公众号",
    list: [
      [
        {
          type: "page",
          label: "公司首页",
          path: "/home",
          value: "/home",
        },
        {
          type: "pageHash",
          label: "公司介绍",
          path: "/home",
          value: "infoBanner",
        },
        {
          type: "pageHash",
          label: "业务介绍",
          path: "/home",
          value: "businessIntroduction",
        },
        {
          type: "pageHash",
          label: "企业团队",
          path: "/home",
          value: "researchTeam",
        },
        {
          type: "pageHash",
          label: "荣誉资质",
          path: "/home",
          value: "honors",
        },
      ],
      [
        {
          type: "page",
          label: "业务展厅",
          path: "/product",
          value: "/product",
        },
        {
          type: "detail",
          label: "三维可视化",
          path: "/product",
          value: 1,
        },
        {
          type: "detail",
          label: "智慧城市",
          path: "/product",
          value: 4,
        },
        {
          type: "detail",
          label: "测绘业务",
          path: "/product",
          value: 8,
        },
      ],
      [
        {
          type: "page",
          label: "联系我们",
          path: "/contact",
          value: "/contact",
        },
        {
          type: "pageHash",
          label: "联系方式",
          path: "/contact",
          value: "methods",
        },
        {
          type: "pageHash",
          label: "合作咨询",
          path: "/contact",
          value: "cooperation",
        },
      ],
    ],
  },
  en: {
    label1: "Contact Us",
    text1: "0572-88845214",
    location:
      "Address: 10, 11F, Building 1, No.10 Keyuan Road, Science and Technology New Town, Deqing County, Zhejiang Province",
    qrcode: "Scan the code to follow the public number",
    list: [
      [
        {
          type: "page",
          label: "Home",
          path: "/home",
          value: "/home",
        },
        {
          type: "pageHash",
          label: "Company Profile",
          path: "/home",
          value: "infoBanner",
        },
        {
          type: "pageHash",
          label: "Business Introduction",
          path: "/home",
          value: "businessIntroduction",
        },
        {
          type: "pageHash",
          label: "Our Team",
          path: "/home",
          value: "researchTeam",
        },
        {
          type: "pageHash",
          label: "Honors and Qualifications",
          path: "/home",
          value: "honors",
        },
      ],
      [
        {
          type: "page",
          label: "Our Business",
          path: "/product",
          value: "/product",
        },
        {
          type: "detail",
          label: "3D Visualization",
          path: "/product",
          value: 1,
        },
        {
          type: "detail",
          label: "Smart City",
          path: "/product",
          value: 4,
        },
        {
          type: "detail",
          label: "Surveying and mapping business",
          path: "/product",
          value: 8,
        },
      ],
      [
        {
          type: "page",
          label: "Contact Us",
          path: "/contact",
          value: "/contact",
        },
        {
          type: "pageHash",
          label: "Contact information",
          path: "/contact",
          value: "methods",
        },
        {
          type: "pageHash",
          label: "Cooperation Consulting",
          path: "/contact",
          value: "cooperation",
        },
      ],
    ],
  },
});
</script>
