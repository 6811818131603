<!--
 * @Author: ce
 * @Date: 2022-09-05 14:41:08
 * @LastEditors: ce
 * @LastEditTime: 2022-09-08 16:28:37
 * @FilePath: /hitarget-offical/src/views/home/components/businessIntroduction.vue
-->
<template>
  <div id="businessIntroduction" class="business-introduction flex items-ceter content-center pt-40 pb-40">
    <div class="w-1400 flex items-stretch content-center">
      <div class="section-title w-300">
        <div class="title ml-48 mt-40">
          <span>{{ $t('home.sectionTitle1') }}</span>
        </div>
      </div>
      <div class="introduction-item" v-for="(item, index) in infos[locale]" :key="index">
        <div class="inner-item h-full" :style="{ backgroundImage: `url(${ item.icon2 })` }">
          <div class="title-row flex items-center">
            <img :src="item.icon1" alt="" class="h-32 w-32">
            <span class="title ml-8">{{ item.title }}</span>
          </div>
          <!-- <p class="tip mt-8">{{ item.tip }}</p> -->
          <div class="detail-list flex flex-wrap mt-32">
            <div 
              class="list-item w-full" 
              v-for="(z, zIndex) in item.list" 
              :key="zIndex"
              @click="handleClick(z)"
            >
              {{ z.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  import { useRouter } from 'vue-router'
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const router = useRouter()
  const infos = ref({
    zh: [
      {
        title: '三维可视化',
        icon1: require('@/assets/imgs/in-icon-1.png'),
        icon2: require('@/assets/imgs/in-icon-4.png'),
        tip: '三维可视化描述文本描述文本描述文本描述文本',
        list: [
          {
            id: 1,
            title: '三维可视化平台'
          },
          {
            id: 2,
            title: '倾斜摄影建模软件'
          },
          {
            id: 3,
            title: 'LINK滤波软件'
          }
        ]
      },
      {
        title: '智慧城市',
        icon1: require('@/assets/imgs/in-icon-2.png'),
        icon2: require('@/assets/imgs/in-icon-5.png'),
        tip: '三维可视化描述文本描述文本描述文本描述文本',
        list: [
          {
            id: 4,
            title: '智慧水务'
          },
          {
            id: 5,
            title: '数字乡村'
          },
          {
            id: 6,
            title: '未来社区'
          },
          {
            id: 7,
            title: '综合枢纽'
          }
        ]
      },
      {
        title: '测绘业务',
        icon1: require('@/assets/imgs/in-icon-3.png'),
        icon2: require('@/assets/imgs/in-icon-6.png'),
        tip: '三维可视化描述文本描述文本描述文本描述文本',
        list: [
          {
            id: 8,
            title: '工程测量'
          },
          {
            id: 9,
            title: '地下空间测绘'
          },
          {
            id: 10,
            title: '界限与不动产测绘'
          },
          {
            id: 11,
            title: '测绘航空摄影'
          }
        ]
      }
    ],
    en: [
      {
        title: '3D Visualization',
        icon1: require('@/assets/imgs/in-icon-1.png'),
        icon2: require('@/assets/imgs/in-icon-4.png'),
        tip: '3D visual description of the text',
        list: [
          {
            id: 1,
            title: '3D Visualization platform'
          },
          {
            id: 2,
            title: 'Tilt photography modeling software'
          },
          {
            id: 3,
            title: 'Tilt photography modeling software LINK filtering software'
          }
        ]
      },
      {
        title: 'Smart City',
        icon1: require('@/assets/imgs/in-icon-2.png'),
        icon2: require('@/assets/imgs/in-icon-5.png'),
        tip: '3D visual description of the text',
        list: [
          {
            id: 4,
            title: 'Smart Water'
          },
          {
            id: 5,
            title: 'Digital Village'
          },
          {
            id: 6,
            title: 'Future Community'
          },
          {
            id: 7,
            title: 'Integrated Hub'
          }
        ]
      },
      {
        title: 'Surveying and mapping business',
        icon1: require('@/assets/imgs/in-icon-3.png'),
        icon2: require('@/assets/imgs/in-icon-6.png'),
        tip: '3D visual description of the text',
        list: [
          {
            id: 8,
            title: 'Engineering Surveying'
          },
          {
            id: 9,
            title: 'Underground space mapping'
          },
          {
            id: 10,
            title: 'Boundary and real estate mapping'
          },
          {
            id: 11,
            title: 'Mapping aerial photography'
          }
        ]
      }
    ]
  })

  const handleClick = ({id}) => {
    router.push({
      path: '/product',
      query: {
        id: id
      }
    })
  }
</script>

<style lang="scss" scoped>
  .business-introduction {
    background-image: url('~@/assets/imgs/business-introduction-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: overlay;
    .section-title {
      position: relative;
      background-image: url('~@/assets/imgs/business-introduction-1.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      .title {
        font-family: 'HarmonyOS Sans';
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #202632;
      }
    }
    .introduction-item {
      flex-grow: 0;
      .inner-item {
        padding: 40px;
        width: 367px;
        box-sizing: border-box;
        background-size: 160px 160px;
        background-position: calc(100% - 10px) calc(100% - 10px);
        background-repeat: no-repeat;
      }
      .title-row {
        .title {
          font-family: 'HarmonyOS Sans';
          font-weight: 700;
          font-size: 28px;
          line-height: 33px;
          color: #202632;
        }
      }
      .tip {
        font-family: 'HarmonyOS Sans';
        font-size: 14px;
        line-height: 16px;
        color: #9BA3B4;
      }
      .detail-list {
        .list-item {
          font-family: 'HarmonyOS Sans';
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #626773;
          margin-top: 24px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: #226CFF;
          }
          &.w-full {
            &:nth-child(1) {
              margin-top: 0;
            }
          }
          &.w-half {
            &:nth-child(1), &:nth-child(2) {
              margin-top: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        background: linear-gradient(139.25deg, #FFFFFF 25.16%, #E4EBF9 80.52%);
      }
      &:nth-child(3) {
        background: #FFFFFF;
        border: 1px solid #E9EEFF;
      }
      &:nth-child(4) {
          background: linear-gradient(139.25deg, #FFFFFF 25.16%, #C3CFE7 80.52%);
          border-radius: 0px 12px 12px 0px;
      }
    }
  }
</style>